import { Environment } from '../../../../../common/model/Environment';

export const BMW_INSPECTION_SERVICE_BASE_URLS: Record<Environment, string> = {
  local: 'http://localhost:3000',
  dev: 'https://bmw-inspection-service-dev.awsmanninp.manheim.com',
  int1: 'https://bmw-inspection-service-int1.awsmanninp.manheim.com',
  int5: 'https://bmw-inspection-service-int5.awsmanninp.manheim.com',
  uat: 'https://bmw-inspection-service-uat.awsmanni.manheim.com',
  prod: 'https://bmw-inspection-service-prod.awsmanni.manheim.com'
};
